@import "./pages/_login.page.scss";

$screen-sm: 769px;

body {
  ::-webkit-scrollbar {
    display: none !important;
  }
}
.layout-wrapper {
  display: flex;
  height: 100vh;
  overflow: scroll;
  @media only screen and (max-width: $screen-sm) {
    flex-direction: column;
  }
}
.sidebar-container {
  @media only screen and (max-width: $screen-sm) {
    display: none;
  }
}
.mobile-navbar-container {
  @media only screen and (min-width: $screen-sm) {
    display: none;
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 20px;
  padding: 5px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #25b09b;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
/* HTML: <div class="loader"></div> */
.loader-dark {
  width: 20px;
  padding: 5px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #fff;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
